import React from "react";
import Hero from "../../components/hero";
import Brands from "../../components/Brands";
import Footer from "../../components/footer";
import Team from "../../components/Team";

import RoadMap from "../../components/RoadMap";
import About from "../../components/About/About";
import Loader from "../../components/Loader";
import Homepage from "../../components/Homepage/Homepage";
import FAQ from "../../components/Common/FAQ/FAQ";
import OurNFT from "../../components/Common/OurNFT/OurNFT";
import Buy from "../../components/Common/How to Buy/Buy";
import Header from "../../components/header";

const Home = (props) => {
  return (
    <>
      <div className=" tw-overflow-x-hidden">
        {/* <Hero isRegister={props.isRegister}/> */}
        {/* <div className=" tw-overflow-x-hidden">
        <Brands />
      </div>
      <About />
      <Team /> */}
        <Header props={props} />
        <Homepage props={props} />
        {/* <RoadMap /> */}

        <FAQ />
        <OurNFT />
        <Buy />
        <Footer />
      </div>
      {props.loader && <Loader />}
    </>
  );
};

export default Home;
