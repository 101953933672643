import React, { useState } from "react";
import bannerimg from "../../assets/images/image/banimg.svg";
import cardimg1 from "../../assets/images/image/cardimg1.svg";
import cardimg2 from "../../assets/images/image/cardimg2.svg";
import leftarrowicon from "../../assets/images/image/leftarrowicon.svg";
import rightarrowicon from "../../assets/images/image/rightarrowicon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";

import scardimg1 from "../../assets/images/image/swipercardimg.svg";

import roadmapimg1 from "../../assets/images/image/roadmapimg1.svg";
import roadmapimg2 from "../../assets/images/image/roadmapimg2.svg";
import rmonkey from "../../assets/images/image/rmonkey.svg";
import "./Homepage.scss";
import roadmapicon1 from "../../assets/images/image/roadmapicon1.svg";
import roadmapicon2 from "../../assets/images/image/roadmapicon2.svg";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import { useSwitchChain, useAccount, useDisconnect } from "wagmi";

// import FAQ from "../../components/FAQ/FAQ";
// import OurNFT from "../../components/OurNFT/OurNFT";
// import Buy from "../../components/How to Buy/Buy";

const Homepage = ({ props }) => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = () => {
    if (swiperInstance) {
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  };

  const slidePrev = () => {
    if (swiperInstance) swiperInstance.slidePrev();
  };

  const slideNext = () => {
    if (swiperInstance) swiperInstance.slideNext();
  };

  const { address, isConnecting, isConnected, isDisconnected } = useAccount();
  const navigate = useNavigate();

  function checkLogin() {
    if (isDisconnected) {
      alert("Kindly Connect your Wallet");
      return;
    }
    if (!props.isRegister) {
      alert(
        "You are not a register memeber, Kindly Register yourself in the APS community"
      );
      return;
    }

    navigate("/user-pannel");
  }

  function checkRegistration() {
    if (isDisconnected) {
      alert("Kindly Connect your Wallet");
      return;
    }
    if (props.isRegister) {
      alert("You are already a registered member of APS community");
      return;
    }

    navigate("/register");
  }
  const slidesData = [
    { text: "Zero Risk", image: scardimg1 },
    { text: "Instant transactions", image: scardimg1 },
    { text: "Immutability of conditions", image: scardimg1 },
    { text: "Transparency and Anonymity", image: scardimg1 },
    { text: "Decentralization", image: scardimg1 },
    { text: "100% online", image: scardimg1 },
  ];
  return (
    <div>
      {/* <Header/> */}

      <section className="home">
        <div className="container">
          <section className="banner-section">
            <div className="row g-3">
              <div className="col-lg-7 col-md-12 col-12">
                <div className="banner-left">
                  <h1 className="h-text-1">
                    The Next-Gen
                    <br className="break" />
                    <span className="h-text-1 span-gr tw-lh-[55px]">
                      {" "}
                      Decentralized <br />
                      Referral Program
                      <br className="break" />
                    </span>
                  </h1>
                  <p className="p-text-1">
                    Get ready to turn your phone into a fun, rewarding machine
                    with Apes Run! Our unique "Shake to Earn" model combines
                    physical activity with a gamified experience, letting you
                    earn tokens by simply shaking your phone. No app downloads
                    or complex setups—just join our bot on Telegram and start
                    shaking!
                  </p>
                  <div className="banner-btn-section">
                    <button className="btn-login" onClick={checkLogin}>
                      Login
                    </button>
                    <a className="btn-register">
                      <button
                        className="btn-register"
                        onClick={checkRegistration}>
                        Registration
                      </button>
                    </a>
                    <a
                      className="btn-register"
                      href="/ApesRunMLM.pdf"
                      target="_blank">
                      <button className="btn-register">Overview(APS)</button>
                    </a>
                  </div>
                  <div className="div-banner-foot">
                    <h2 className="h-text-2">
                      432k+
                      <span>Collections</span>
                    </h2>
                    <h2 className="h-text-2">
                      $1M+
                      <span>Raised</span>
                    </h2>
                    <h2 className="h-text-2">
                      100K+
                      <span>Community</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-12">
                <div className="banner-right">
                  <img src={bannerimg} alt="banner" className="banner-img" />
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="container">
          <div className="swiper-container">
            {/* <Swiper
            onSwiper={setSwiperInstance}
            onSlideChange={handleSlideChange}
            slidesPerView={1}
            spaceBetween={20}
            freeMode={true}
            modules={[FreeMode]}
            className="my-swiper  "
          > */}
            {/* <SwiperSlide> */}
            <section className="home-section-1">
              <div className="row g-2">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="home-section-left">
                    <h2 className="h-text-3">
                      Where innovation <br />
                      meets opportunity
                    </h2>

                    <p className="p-text-2">
                      Welcome to apesrun.com, where innovation meets
                      opportunity! Our company is built on the revolutionary
                      power of blockchain technology, providing a 100%
                      blockchain-controlled income system. At apesrun.com, we
                      are dedicated to offering a unique and transparent
                      platform where anyone can earn through multiple avenues,
                      from referral-based income to gaming rewards. Our mission
                      is to empower individuals around the globe to achieve
                      financial freedom through innovative earning methods. With
                      six powerful income streams, including our groundbreaking
                      matrix system and global royalty opportunities, we provide
                      an environment where both experienced and new networkers
                      can thrive. At apesrun.com, we believe in fairness,
                      transparency, and the power of technology to transform
                      lives. By leveraging blockchain, we ensure that every
                      transaction and earning process is secure, decentralized,
                      and reliable. Whether you’re looking to build a team, earn
                      passively, or even make money through gaming, we have the
                      tools and the community to help you succeed. Join us and
                      become part of a fast-growing community that’s redefining
                      financial success in the digital age.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="home-section-right">
                    <div className="card banner-card">
                      <img src={cardimg1} alt="cardimg" className="card-img" />
                      <div className="banner-card-foot">
                        <p className="p-text-3">
                          Fun and Engaging: Say goodbye to boring earn models.
                          With Apes Run, every shake brings you closer to
                          exciting rewards.
                        </p>
                      </div>
                    </div>

                    <div className="card banner-card   banner-card-position">
                      <img src={cardimg2} alt="cardimg" className="card-img" />
                      <div className="banner-card-foot">
                        <p className="p-text-3">
                          Fun and Engaging: Say goodbye to boring earn models.
                          With Apes Run, every shake brings you closer to
                          exciting rewards.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* </SwiperSlide>

            <SwiperSlide>
              <section className="home-section-1">
                <div className="row g-2">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="home-section-left">
                      <h2 className="h-text-3">
                        Where innovation <br />
                        meets opportunity
                      </h2>

                      <p className="p-text-2">
                        Welcome to apesrun.com, where innovation meets
                        opportunity! Our company is built on the revolutionary
                        power of blockchain technology, providing a 100%
                        blockchain-controlled income system. At apesrun.com, we
                        are dedicated to offering a unique and transparent
                        platform where anyone can earn through multiple avenues,
                        from referral-based income to gaming rewards. Our
                        mission is to empower individuals around the globe to
                        achieve financial freedom through innovative earning
                        methods. With six powerful income streams, including our
                        groundbreaking matrix system and global royalty
                        opportunities, we provide an environment where both
                        experienced and new networkers can thrive. At
                        apesrun.com, we believe in fairness, transparency, and
                        the power of technology to transform lives. By
                        leveraging blockchain, we ensure that every transaction
                        and earning process is secure, decentralized, and
                        reliable. Whether you’re looking to build a team, earn
                        passively, or even make money through gaming, we have
                        the tools and the community to help you succeed. Join us
                        and become part of a fast-growing community that’s
                        redefining financial success in the digital age.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="home-section-right">
                      <div className="card banner-card">
                        <img
                          src={cardimg1}
                          alt="cardimg"
                          className="card-img"
                        />
                        <div className="banner-card-foot">
                          <p className="p-text-3">
                            Fun and Engaging: Say goodbye to boring earn models.
                            With Apes Run, every shake brings you closer to
                            exciting rewards.
                          </p>
                        </div>
                      </div>

                      <div className="card banner-card   banner-card-position">
                        <img
                          src={cardimg2}
                          alt="cardimg"
                          className="card-img"
                        />
                        <div className="banner-card-foot">
                          <p className="p-text-3">
                            Fun and Engaging: Say goodbye to boring earn models.
                            With Apes Run, every shake brings you closer to
                            exciting rewards.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </SwiperSlide>

            <SwiperSlide>
              <section className="home-section-1">
                <div className="row g-2">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="home-section-left">
                      <h2 className="h-text-3">
                        Where innovation <br />
                        meets opportunity
                      </h2>

                      <p className="p-text-2">
                        Welcome to apesrun.com, where innovation meets
                        opportunity! Our company is built on the revolutionary
                        power of blockchain technology, providing a 100%
                        blockchain-controlled income system. At apesrun.com, we
                        are dedicated to offering a unique and transparent
                        platform where anyone can earn through multiple avenues,
                        from referral-based income to gaming rewards. Our
                        mission is to empower individuals around the globe to
                        achieve financial freedom through innovative earning
                        methods. With six powerful income streams, including our
                        groundbreaking matrix system and global royalty
                        opportunities, we provide an environment where both
                        experienced and new networkers can thrive. At
                        apesrun.com, we believe in fairness, transparency, and
                        the power of technology to transform lives. By
                        leveraging blockchain, we ensure that every transaction
                        and earning process is secure, decentralized, and
                        reliable. Whether you’re looking to build a team, earn
                        passively, or even make money through gaming, we have
                        the tools and the community to help you succeed. Join us
                        and become part of a fast-growing community that’s
                        redefining financial success in the digital age.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="home-section-right">
                      <div className="card banner-card">
                        <img
                          src={cardimg1}
                          alt="cardimg"
                          className="card-img"
                        />
                        <div className="banner-card-foot">
                          <p className="p-text-3">
                            Fun and Engaging: Say goodbye to boring earn models.
                            With Apes Run, every shake brings you closer to
                            exciting rewards.
                          </p>
                        </div>
                      </div>

                      <div className="card banner-card   banner-card-position">
                        <img
                          src={cardimg2}
                          alt="cardimg"
                          className="card-img"
                        />
                        <div className="banner-card-foot">
                          <p className="p-text-3">
                            Fun and Engaging: Say goodbye to boring earn models.
                            With Apes Run, every shake brings you closer to
                            exciting rewards.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </SwiperSlide>
          </Swiper> */}
          </div>
        </div>
        <div className="container">
          <section className="home-section-1">
            <div className="row g-2">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="home-section-left">
                  <h2 className="h-text-3">Core Benefits</h2>

                  <p className="p-text-2">
                    By leveraging blockchain, we ensure that every transaction
                    and earning process is secure, decentralized, and reliable.
                    Whether you’re looking to build a team, earn passively, or
                    even make money through gaming, we have the tools and the
                    community to help you succeed.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="upcoming-swiper-btn d-flex justify-content-between">
            <button
              className={`swiper-btn swiper-btn-left ${
                isBeginning ? "disabled" : ""
              }`}
              onClick={slidePrev}
              disabled={isBeginning}>
              <img
                src={leftarrowicon}
                width={50}
                height={50}
                className={`${isBeginning ? "disabled" : ""}`}
                alt="Arrow_Right_Button_Image"
              />
            </button>

            <button
              className={`swiper-btn swiper-btn-right ${
                isEnd ? "disabled" : ""
              }`}
              onClick={slideNext}
              disabled={isEnd}>
              <img
                src={rightarrowicon}
                width={50}
                height={50}
                className={`${isEnd ? "disabled" : ""}`}
                alt="Arrow_Left_Button_Image"
              />
            </button>
          </div>
          <div className="swiper-container-2">
            <Swiper
              onSwiper={setSwiperInstance}
              onSlideChange={handleSlideChange}
              slidesPerView={3.2}
              spaceBetween={20}
              freeMode={true}
              modules={[FreeMode]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3.2,
                },
              }}
              className="my-swiper my-swiper-monkey">
              {slidesData.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="card swiper-card">
                    <img src={slide.image} alt="card" className="s-card-img" />
                    <div className="s-card-foot">
                      <p className="s-card-text">{slide.text}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="container">
          <section className="roadmap">
            <h2 className="h-text-3 text-center">Road Map</h2>

            <h2 className="h-text-3 text-center"> (Millionaire Maker) </h2>

            <div className="roadmap-container-1  roadmap-hide tab-hide mobile-hide">
              <img src={roadmapimg1} alt="roadmap" className="roadmap-img-1" />

              <div className="roadmap-div rd-div-1">
                <p className="roadmap-text-1">1</p>
                <p className="roadmap-text-2">
                  First Target Achievers Reward <br /> Distribution
                </p>
              </div>

              <div className="roadmap-div rd-div-2">
                <p className="roadmap-text-1">2</p>
                <p className="roadmap-text-2">
                  First Monthly Global royalty <br /> distribution
                </p>
              </div>

              <div className="roadmap-div rd-div-3">
                <p className="roadmap-text-1">3</p>
                <p className="roadmap-text-2">Start Earn by playing games</p>
              </div>

              <div className="roadmap-div rd-div-4">
                <p className="roadmap-text-1">4</p>
                <p className="roadmap-text-2">APS Coin launch</p>
              </div>
            </div>

            <div className="roadmap-container-2  roadmap-hide  tab-hide mobile-hide">
              <img src={roadmapimg2} alt="roadmap" className="roadmap-img-2" />

              <div className="roadmap-div rd-div-5">
                <p className="roadmap-text-1">5</p>
                <p className="roadmap-text-2">
                  Start Monthly Global Royalty <br />
                  Distribution Without any condition
                </p>
              </div>

              <div className="roadmap-div rd-div-6">
                <p className="roadmap-text-1">6</p>
                <p className="roadmap-text-2">APS wallet</p>
              </div>

              <div className="roadmap-div rd-div-7">
                <p className="roadmap-text-1">7</p>
                <p className="  roadmap-text-3">(Continue)</p>
              </div>
            </div>

            <img
              src={rmonkey}
              alt="rmonkey"
              className="r-monkey   roadmap-hide  tab-hide mobile-hide"
            />

            <div className="roadmap-section-wrapper desktop-hide">
              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon1}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">1</p>
                  <p className="roadmap-text-2">
                    First Target Achievers Reward <br /> Distribution
                  </p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon2}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">2</p>
                  <p className="roadmap-text-2">
                    First Monthly Global royalty
                    <br /> Distribution
                  </p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon2}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">3</p>
                  <p className="roadmap-text-2">Start Earn by playing games</p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon2}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">4</p>
                  <p className="roadmap-text-2">APS Coin launch</p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon1}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">5</p>
                  <p className="roadmap-text-2">
                    Start Monthly Global Royalty <br />
                    Distribution Without any condition
                  </p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon2}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">6</p>
                  <p className="roadmap-text-2">APS Wallet</p>
                </div>
              </div>

              <div className="roadmap-wrapper-div-1">
                <img
                  src={roadmapicon2}
                  alt="roadmapicon"
                  className="roadmap-icon"
                />

                <div className="roadmap-div-inside">
                  <p className="roadmap-text-1">7</p>
                  <p className="roadmap-text-3">(Continue)</p>
                </div>
              </div>
            </div>

            <img
              src={rmonkey}
              alt="rmonkey"
              className="r-monkey-mobile  desktop-hide"
            />
          </section>
        </div>

        {/* 
      <FAQ />

      <OurNFT />

      <Buy /> */}
      </section>
    </div>
  );
};

export default Homepage;
