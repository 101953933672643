import React from "react";
import buyimg1 from "../../../assets/images/card1.svg";
import buyimg2 from "../../../assets/images/card2.svg";
import buyimg3 from "../../../assets/images/card3.svg";

// import { Button } from 'react-bootstrap'
import "../How to Buy/Buy.scss";
import Button from "../../Button";

const Buy = () => {
  return (
    <div className="main-how-buy">
      <div className="buy-head">
        <h4>How To Buy</h4>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="how-buy">
              <div className="card">
                <img
                  src={buyimg1}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body">
                  <h5 className="card-title">Connect Wallet</h5>
                  <p className="card-text">
                    To purchase APS Token you need to connect your wallet, which
                    involves approving the contract on your wallet
                  </p>
                </div>
              </div>

              <div className="card">
                <img
                  src={buyimg2}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body">
                  <h5 className="card-title">Select payment Type</h5>
                  <p className="card-text mb-3">
                    Select from our available MATIC & USDT for payment
                  </p>
                </div>
              </div>

              <div className="card">
                <img
                  src={buyimg3}
                  width={327}
                  height={209}
                  className="card-img-top"
                  alt="buy-img"
                />
                <div className="card-body">
                  <h5 className="card-title">Buy APS Token</h5>
                  <p className="card-text">
                    Simply input your amount, select buy and approve the payment
                    on your wallet
                  </p>
                </div>
              </div>
            </div>
            <div className="buy-aps-div w-100">
              <a href="https://www.apesrun.com/" target="blank">
              <Button className='btn-buy-aps' label="Buy APS NOW!!"></Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;
