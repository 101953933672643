import React, { useEffect, useState } from "react";
import { FaCar } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

import { FaAngleDown } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import Button from "../Button";
import { MdMenu } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";

import { useWeb3Modal, useWeb3ModalTheme, use } from "@web3modal/wagmi/react";
import { useAccount, useReadContract, useWriteContract } from "wagmi";

const Header = ({ props }) => {
  console.log("props", props);
  const [open1, setOpen] = useState(false);
  const [holdersDropdownOpen, setHoldersDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);

  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const [scrollBackground, setScrollBackground] = useState(false);

  const { open, close } = useWeb3Modal();
  const { isConnected, isDisconnected, chain } = useAccount();
  const { address } = useAccount();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollBackground(true);
      } else {
        setScrollBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function checkLogin() {
    if (isDisconnected) {
      alert("Kindly Connect your Wallet");
      return;
    }
    console.log("props.isRegister", props);
    if (!props.isRegister) {
      alert(
        "You are not a register memeber, Kindly Register yourself in the APS community"
      );
      return;
    }

    navigate("/user-pannel");
  }

  function checkRegistration() {
    if (isDisconnected) {
      alert("Kindly Connect your Wallet");
      return;
    }
    console.log("props.isRegister", props.isRegister);
    if (props.isRegister) {
      alert("You are already a registered member of APS community");
      return;
    }

    navigate("/register");
  }

  return (
    <header>
      <nav
        className={`tw-w-full position-fixed  tw-bg-[rgba(103,96,96,0.17)]  tw-z-20 ${
          hasScrolled ? " tw-top-0 tw-backdrop-blur" : "tw-backdrop-blur"
        } tw-bg-[black] sm:tw-relative md:tw-fixed`}>
        <div className="container tw-mx-auto tw-flex tw-items-center tw-justify-between tw-h-[4rem] md:tw-h-[6rem]">
          {/* Logo */}
          <a href="/">
            <div>
              <img
                src={require("../../assets/images/logo1.png")}
                alt="Logo"
                className="tw-object-contain tw-w-[50px] md:tw-w-[80px]"
              />
            </div>
          </a>
          {/* <div className="mobile-view connect-wallet-mobile-btn">
            <Button
              onClick={open}
              className="tw-bg-[#2F80ED] tw-text-white tw-w-full tw-py-2 tw-rounded-lg"y
              label={
                isConnected
                  ? `${address.slice(0, 4)}....${address.slice(-4)}`
                  : "Connect Wallet"
              }
            />
          </div> */}
          {/* Desktop Menu */}
          {/* <ul className="lg:tw-flex tw-hidden tw-items-center tw-gap-8 tw-font-[Poppins]">
            {[
              { label: "Home", path: "/", section: "" },
              { label: "About Us", path: "/", section: "aboutSection" },
              { label: "Tokenomics", path: "/", section: "tokenSection" },
              { label: "Road Map", path: "/", section: "roadSection" },
              { label: "Team", path: "/", section: "teamSection" },
              { label: "Staking", path: "/staking" },
              { label: "MLM", path: "/" },
              0
            ].map((item, index) => (
              <li key={index}>
                {item.external ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`tw-text-white ${isActive(item.path) ? "active" : ""}`}
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    onClick={() => item.section && handleNavigate(item.path, item.section)}
                    className={`tw-text-white ${isActive(item.path) ? "active" : ""}`}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul> */}

          {/* Desktop Connect Wallet Button */}
          <div className="desktop-view">
            <div className="d-flex align-items-center gap-3">
              <Button
                onClick={() => open()}
                className="btn-login "
                label={
                  isConnected
                    ? `${address.slice(0, 4)}....${address.slice(-4)}`
                    : "Connect Wallet"
                }
              />
              {/* <Button

              onClick={checkLogin}
              label={"Login"}
              className={"btn-login "}

            /> */}
              {/* </Link> */}

              {/* <Button
              onClick={checkRegistration}
              label={"Registration"}
              className={"btn-register "}

            /> */}
            </div>
          </div>
          {/* Mobile Menu Toggle */}
          <div className="tw-text-3xl lg:tw-hidden  tw-z-50">
            <Button
              onClick={() => open()}
              className="btn-login "
              label={
                isConnected
                  ? `${address.slice(0, 4)}....${address.slice(-4)}`
                  : "Connect Wallet"
              }
            />
            {/* {isMobileMenuOpen ? <MdOutlineClose color="white" /> : <MdMenu color="white" />} */}
          </div>

          {/* Mobile Menu */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
